<template>
  <div id="mkt-unsubscribe">
    <table class="unsubscribed-page">
      <tr>
        <td>
          <table class="email-body">
            <tr>
              <td class="email-header" align="center">
                <a href="#">
                  <v-img :src="logo" max-height="120" max-width="120" />
                </a>
              </td>
            </tr>

            <tr>
              <td class="news-section">
                <div id="templateBody" class="bodyContent rounded6">
                  <div v-if="userSubStaus==='subscribed'">
                    <h2> Do you want to unsubscribe? </h2>
                    <div>You will no longer receive email marketing from this list.
                    </div>
                  </div>
                  <v-form id="unsubscribeform" ref="form">
                    <input type="hidden" name="u" value="">
                    <input type="hidden" name="id" value="d162cfcfa6">
                    <input type="hidden" name="e" value="">
                    <div class="groups">
                      <div v-if="userSubStaus==='unsubscribed'" id="unsub-reason-success" class="alert">
                        Thanks for the feedback!
                      </div>
                      <div v-else class="unsub-reason-options">
                        <div id="unsub-reason-error" class="error" style="display:none;">
                          There were some errors, please try again later
                        </div>
                        <h3 class="unsub-title">Please let us know why you unsubscribed:</h3>
                        <ul class="unsub-options">
                          <li>
                            <label class="radio" for="r1">
                              <input id="r1" type="radio" name="unsub-reason" value="NORMAL" @click="notextarea()">
                              <span>I no longer want to receive these emails</span>
                            </label>
                          </li>
                          <li>
                            <label class="radio" for="r2">
                              <input id="r2" type="radio" name="unsub-reason" value="NOSIGNUP" @click="notextarea()">
                              <span>I never signed up for this mailing list</span>
                            </label>
                          </li>
                          <li>
                            <label class="radio" for="r3">
                              <input id="r3" type="radio" name="unsub-reason" value="INAPPROPRIATE" @click="notextarea()">
                              <span>The emails are inappropriate</span>
                            </label>
                          </li>
                          <li>
                            <label class="radio" for="r4">
                              <input id="r4" type="radio" name="unsub-reason" value="SPAM" @click="notextarea()">
                              <span>The emails are spam and should be reported</span>
                            </label>
                          </li>
                          <li>
                            <label class="radio" for="r5">
                              <input id="r5" type="radio" name="unsub-reason" value="OTHER" @click="showtextarea()">
                              <span>Other (fill in reason below)</span>
                            </label>
                            <br>
                            <textarea id="unsub-reason-desc" minlength="10" maxlength="200" name="unsub-reason-desc" style="" row="2" cols="20" class="required" @input="inputDesc" />
                          </li>
                        </ul>
                      </div>
                    </div>
                    <!-- <input class="formEmailButton" type="submit" name="submit" value="Submit"> -->
                    <v-btn v-if="userSubStaus==='subscribed'" block depressed media class="tile text-capitalize mkt-btn" :disabled="disabled" @click="submitAndunsub">Submit & unsubscribe</v-btn>
                    <v-btn v-else block depressed media class="tile text-capitalize mkt-btn" @click="resubscribe">Resubscribe</v-btn>
                  </v-form>
                  <br>
                  <a href="https://www.onechannel.one/?utm_source=mkt_unsubscribe&utm_campaign=unsubscribe_page&utm_medium=email">« View our website</a>
                </div>
              </td>
            </tr>

            <tr>
              <td class="footer">
                You're receiving this email because you subscribed this newsletter. You can <a href="#">Unsubscribe</a> any time.
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import logo from '@/assets/logo1.png'
import { emailUnSbuscribeReason } from '@/api/user'

export default {
  name: 'Unsubscribe',
  data() {
    return {
      logo: logo,
      disabled: true,
      isReEncode: false,
      userSubStaus: 'subscribed',
      user_email: ''
    }
  },
  created() {
    // will be called after the email id of the user has been fetched
    var that = this
    window.onload = function() {
      // eslint-disable-next-line
      window.wzrk_email_fetched=(emailStr)=>{
        that.user_email = emailStr
      }
      window.wzrk_email_subscription = (status) => {
        // status 0 : unsubscribed, status 1 : subscribed
        // todo - you can show a success message to the user from here
        var statusLabel = 'subscribed'
        if (status === '0') {
          statusLabel = 'unsubscribed'
        }
        that.userSubStaus = statusLabel
        alert("You've been " + statusLabel)
      }
      // eslint-disable-next-line
      $WZRK_WR.getEmail(this.isReEncode)
    }
  },
  methods: {
    showtextarea() {
      var reasonDesc = document.forms.unsubscribeform.elements['unsub-reason-desc'].value
      this.disabled = reasonDesc === ''
      document.getElementById('unsub-reason-desc').style.display = 'block'
    },
    notextarea() {
      this.disabled = false
      document.getElementById('unsub-reason-desc').style.display = 'none'
    },
    submitAndunsub() {
      // eslint-disable-next-line
      $WZRK_WR.unSubEmail(this.isReEncode);
      var reason = document.forms.unsubscribeform.elements['unsub-reason'].value
      var reasonDesc = document.forms.unsubscribeform.elements['unsub-reason-desc'].value
      emailUnSbuscribeReason({ 'email': this.user_email, 'reason': reason, 'reasonDesc': reasonDesc }).then(res => {
        console.log(res)
      }, error => {
        console.log(error)
      })
    },
    inputDesc() {
      var reasonDesc = document.forms.unsubscribeform.elements['unsub-reason-desc'].value
      this.disabled = reasonDesc.length < 10
    },
    resubscribe() {
      // eslint-disable-next-line
        $WZRK_WR.subEmail(this.isReEncode);
    }
  }
}
</script>

<style lang="scss" scoped>
#mkt-unsubscribe{

.unsub-options{
    margin-top:10px!important;
}

.mkt-btn{
    margin-top: 20px;
    background-image: linear-gradient(45deg,#8C9EFF, #E040FB)!important;
    font-weight: bold;
    letter-spacing: 0.05em;
}

.radio{
    span{
        padding-left: 10px;
    }
}

.groups{
    margin-top: 10px;
}

td,
.groups p {
  font-size: 13px;
  color: #878787;
}

.unsubscribed-page h1,
.unsubscribed-page h2 {
  color: black;
}

.unsubscribed-page h1 {
  font-size: 25px;
}

.unsubscribed-page h2 {
  font-size: 20px;
}

.unsubscribed-page a {
  color: #2F82DE;
  font-weight: bold;
  text-decoration: none;
}

.unsubscribed-page {
//   background: #C7C7C7;
  width: 100%;
  padding: 20px 0;
  font-family: 'Nunito', sans-serif;
  line-height: 1.5;
}

.email-body {
  max-width: 600px;
  min-width: 320px;
  margin: 0 auto;
  background: white;
  border-collapse: collapse;
}
.email-body img {
  max-width: 100%;
}

.email-header {
  background: black;
  padding: 30px;
}

.news-section {
  padding: 20px 30px;
}

.groups ul {
  margin: 0 0 10px 25px;
  padding: 0;
  display: block;
  padding: 0;
  margin: 0;
  list-style: none;
}
.groups li {
  margin: 0 0 3px 0;
  padding: 2px;
  vertical-align: middle;
}

#unsub-reason-desc{
    border: 1px solid #ddd;
    padding: 10px;
}

.required {
  display: none;
  width: 100%;
  height: 150px;
  color: #5d5d5d;
  text-align: left;
  font-size: 12px;
  overflow: auto;
}
.formEmailButton {
  color: #fff;
  background-color: #34ade3;
  padding: 10px 20px;
  margin: 10px 0;
  outline: none;
  border: 1px solid #34ade3;
  border-radius: 7px;
}

.footer {
  background: #eee;
  padding: 10px;
  font-size: 10px;
  text-align: center;
}

}
</style>
